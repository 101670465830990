export const AirportContentPath = {
  MetarTaf: 'weather/metar-taf',
  Mos: 'weather/mos',
  WindsAloft: 'weather/winds-aloft',
  Discussion: 'weather/discussion',
  AirmetSigmet: 'weather/airsig',
  General: 'info/general',
  Frequencies: 'info/frequencies',
  Remarks: 'info/remarks',
  Services: 'info/services',
  Runways: 'runways',
  Charts: 'charts',
  Fuel: 'fuel',
  Fbo: 'fbo',
  Notams: 'notams'
} as const;

export type AirportContentPathLike = (typeof AirportContentPath)[keyof typeof AirportContentPath];

export interface FboData {
  fboName: string;
}

export interface ChartsData {
  selectSupplement: true;
}

/** Represents the content currently being shown for an airport. */
export interface AirportContent {
  /** The content "path" - more of a type that can also be used as a path if routing is available. */
  path: AirportContentPathLike;

  /** Optional data associated with the "path" */
  data?: FboData | ChartsData;
}
