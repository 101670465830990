import { BreakpointObserver } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, DestroyRef, ElementRef, Input, OnInit, QueryList, ViewChild, ViewChildren, effect, inject, signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { environment } from '@environment';
import { AvcMapsDisplayService, MapIcon, MapWaypoint, MapWaypointType, AvcMapRadialMenuService } from '@garmin-avcloud/avc-maps-display';
import { FlyButtonModule } from '@garmin-avcloud/avcloud-ui-common/button';
import { Icon, Icons, FlightIcons, FlyIconModule } from '@garmin-avcloud/avcloud-ui-common/icon';
import { FlyInputComponent, FlyInputModule } from '@garmin-avcloud/avcloud-ui-common/input';
import { FlyLoadingSpinnerModule } from '@garmin-avcloud/avcloud-ui-common/loading-spinner';
import { Breakpoints } from '@garmin-avcloud/avcloud-ui-common/style-variables';
import { FlyTabsModule, TabComponent } from '@garmin-avcloud/avcloud-ui-common/tabs';
import { AuthService, isStringNonEmpty } from '@garmin-avcloud/avcloud-web-utils';
import { FeaturesService } from '@garmin-avcloud/avcloud-web-utils/feature';
import { FlightRouteControllerService, RouteComputedLeg, UnifiedTokenRequest, UnifiedTokenRequestDesiredLocationTypes, UnifiedTokenResponse } from '@generated/flight-route-service';
import { TOKEN_SEARCH_TYPES } from '@shared/constants/flights/flights-constants';
import { AirportDataSection } from '@shared/enums/airport-data-section.enum';
import { FeatureFlag } from '@shared/enums/feature-flag.enum';
import { State } from '@shared/enums/loading-state.enum';
import { AcdcAircraftTrackResponse } from '@shared/models/acdc-realtime-traffic/acdc-aircraft-track-response.model';
import { AirportModel } from '@shared/models/airport/airport.model';
import { AirportSearchResponse } from '@shared/models/airport/search/airport-search-response.model';
import { AirportSearchResult } from '@shared/models/airport/search/airport-search-result.model';
import { AcdcRealtimeTrafficService } from '@shared/services/acdc-realtime-traffic/acdc-realtime-traffic.service';
import { AirportService } from '@shared/services/airport/airport.service';
import { RecentWaypoint, StoredWaypoint } from '@shared/services/recent-waypoints/recent-waypoints.constants';
import { RecentWaypointsService } from '@shared/services/recent-waypoints/recent-waypoints.service';
import { AirportDataService } from 'projects/avcloud-pilotweb/src/app/features/airport/shared/services/airport-data.service';
import { FavoriteAirportsService } from 'projects/avcloud-pilotweb/src/app/features/airport/shared/services/favorite-airports.service';
import { FavoriteWaypointService } from 'projects/avcloud-pilotweb/src/app/features/map/services/favorite-waypoints/favorite-waypoints.service';
import { Observable, combineLatest, debounceTime, distinctUntilChanged, filter, forkJoin, fromEvent, map, of, switchMap, tap } from 'rxjs';
import { AIRPORT_CONTENT_SIGNAL } from '../../../features/airport/tokens/airport-content.token';
import { AirportContentPath } from '../../../features/airport/types/airport-content.types';
import { InfoService, InfoWindowPosition } from '../../services/map-info/info.service';
import { AircraftSearchCardComponent } from './aircraft-search-card/aircraft-search-card.component';
import { AirportSearchCardComponent } from './airport-search-card/airport-search-card.component';
import { SearchCardComponent } from './search-card/search-card.component';
import { WAYPOINT_ICON_MAP, getWaypointIcon } from './search.utils';

enum SearchTab {
  Results,
  Favorites
}

export interface SearchResult {
  airport?: AirportSearchResult;
  waypoint?: RouteComputedLeg;
  aircraft?: AcdcAircraftTrackResponse;
}

@Component({
  selector: 'pilot-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  standalone: true,
  imports: [
    AirportSearchCardComponent,
    CommonModule,
    FormsModule,
    FlyButtonModule,
    FlyInputModule,
    FlyIconModule,
    FlyLoadingSpinnerModule,
    FlyTabsModule,
    ReactiveFormsModule,
    SearchCardComponent,
    AircraftSearchCardComponent
  ]
})
export class SearchComponent implements OnInit {
  @Input() mapStyling: boolean = false;
  @Input() shrinkWhenClosed: boolean = false;
  @Input() resultFiltering: 'airports' | 'waypoints' | 'aircraft' | 'none' = 'none';
  @ViewChild(FlyInputComponent) private readonly input: FlyInputComponent;
  @ViewChild('recents') private readonly recentsContainer: ElementRef<HTMLDivElement>;
  @ViewChildren(TabComponent) private readonly tabs: QueryList<TabComponent>;

  private readonly airportContent = inject(AIRPORT_CONTENT_SIGNAL);
  private readonly airportDataService = inject(AirportDataService);
  private readonly airportService = inject(AirportService);
  private readonly authService = inject(AuthService);
  private readonly breakpointObserver = inject(BreakpointObserver);
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly destroyRef = inject(DestroyRef);
  private readonly el = inject(ElementRef);
  private readonly favoriteAirportsService = inject(FavoriteAirportsService);
  private readonly favoriteWaypointsService = inject(FavoriteWaypointService);
  private readonly featuresService = inject(FeaturesService);
  private readonly flightRouteService = inject(FlightRouteControllerService);
  private readonly formBuilder = inject(FormBuilder);
  private readonly http = inject(HttpClient);
  private readonly infoService = inject(InfoService);
  private readonly mapService = inject(AvcMapsDisplayService);
  private readonly radialMenuService = inject(AvcMapRadialMenuService, { optional: true });
  private readonly recentWaypointsService = inject(RecentWaypointsService);
  private readonly acdcRealtimeTrafficService = inject(AcdcRealtimeTrafficService);

  readonly SEARCH_DEBOUNCE = 500;
  readonly MAX_RECENTS = 4;
  readonly Icons = Icons;
  readonly FlightIcons = FlightIcons;
  readonly State = State;
  readonly SearchTab = SearchTab;

  protected showSearch: boolean = true;
  protected showSearchContent: boolean = false;
  private isInternetTrafficEnabled = false;

  clickStartedInside = false;

  recentlyViewedAirports: AirportSearchResult[] = [];
  recentlyViewedWaypoints: RouteComputedLeg[] = [];

  favoriteAirports: AirportSearchResult[] = [];
  favoriteWaypoints: RouteComputedLeg[] = [];

  storedWaypoints: StoredWaypoint[] = [];

  formGroup = new FormGroup({
    searchControl: this.formBuilder.control<string>('', { nonNullable: true })
  });

  isMobileOrTabletSize: boolean = false;
  tabShown = SearchTab.Results;
  currentSearchState: State = State.NoSelection;
  recentlyViewedState: State = State.Loading;

  isAuthenticated = toSignal(this.authService.isAuthenticated(), { initialValue: false });

  results = signal<SearchResult[]>([]);

  constructor() {
    effect(() => {
      const results = this.results();
      if (this.currentSearchState !== State.NoSelection) {
        if (results.length > 0) {
          this.currentSearchState = State.Loaded;
        } else {
          this.currentSearchState = State.NoDataAvailable;
        }
      }
    });

    effect(() => {
      let recentWaypoints = this.recentWaypointsService.recentlyStoredWaypoints();
      if (this.resultFiltering === 'airports') {
        recentWaypoints = recentWaypoints.filter((waypoint) => waypoint.type === 'AIRPORT');
      } else if (this.resultFiltering === 'waypoints') {
        recentWaypoints = recentWaypoints.filter((waypoint) => waypoint.type !== 'AIRPORT');
      }

      // Waypoints are stored from least to most recent, but we want the most recent result to show first
      this.storedWaypoints = structuredClone(recentWaypoints)?.reverse().slice(0, this.MAX_RECENTS) ?? [];

      // If we selected a recent, it moved to the front, so focus the first recent
      if (this.recentsContainer != null && this.recentsContainer.nativeElement.contains(document.activeElement)) {
        (this.recentsContainer.nativeElement.children.item(0) as HTMLButtonElement).focus();
      }
      this.recentlyViewedState = State.Loaded;
      this.getRecentAirportData(this.storedWaypoints)
        .subscribe((airports: AirportSearchResult[]) => {
          this.recentlyViewedAirports = airports;
        });
      this.getRecentWaypointData(this.storedWaypoints)
        .subscribe((waypoints: RouteComputedLeg[]) => {
          this.recentlyViewedWaypoints = [...this.recentlyViewedWaypoints, ...waypoints];
        });
    });

    effect(() => {
      if (this.isAuthenticated()) {
        if (this.resultFiltering !== 'waypoints') {
          this.favoriteAirportsService.getFavoriteAirportIds()
            .pipe(
              switchMap((airportSettings) => this.airportService.getAirportsByIds(airportSettings.map((setting) => setting.value))),
              takeUntilDestroyed(this.destroyRef)
            ).subscribe((results) => {
              this.favoriteAirports = results?.airports ?? [];
            });
        }

        if (this.resultFiltering !== 'airports') {
          const favoriteWaypoints = this.favoriteWaypointsService.favoriteWaypoints();

          if (favoriteWaypoints.length === 0) {
            this.favoriteWaypoints = [];
          } else {
            forkJoin(this.favoriteWaypointsService.getFavorites().map((value) => this.getWaypointInfo(value.id, value.cc, true)))
              .pipe(
                takeUntilDestroyed(this.destroyRef),
                map((responses) => responses.map((response) => response.resultsList?.at(0))),
                filter((searchResults): searchResults is RouteComputedLeg[] => searchResults != null)
              )
              .subscribe((results) => {
                this.favoriteWaypoints = results;
              });
          }
        }

        this.featuresService.isFeatureActive(FeatureFlag.INTERNET_TRAFFIC)
          .pipe((takeUntilDestroyed(this.destroyRef)))
          .subscribe((active) => {
            this.isInternetTrafficEnabled = active;
          });
      }
    });
  }

  ngOnInit(): void {
    this.formGroup.controls.searchControl.valueChanges.pipe(
      tap((value) => {
        this.tabs.get(SearchTab.Results)?.selectTab();
        if (!isStringNonEmpty(value)) {
          this.currentSearchState = State.NoSelection;
          return;
        } else {
          this.currentSearchState = State.Loading;
        }
      }),
      debounceTime(this.SEARCH_DEBOUNCE),
      filter((search): search is string => search !== ''),
      switchMap((search) => {
        const airports = this.resultFiltering !== 'waypoints' && this.resultFiltering !== 'aircraft' ? this.airportService.searchAirports(search) : of(null);
        const waypoints = this.resultFiltering !== 'airports' && this.resultFiltering !== 'aircraft' ? this.getWaypointInfo(search) : of(null);
        const aircraft = this.isInternetTrafficEnabled && this.resultFiltering !== 'waypoints' && this.resultFiltering !== 'airports' ? this.acdcRealtimeTrafficService.getTrafficTrackByRegistration(search): of(null);
        return combineLatest({
          airportsResponse: airports,
          waypointsResponse: waypoints,
          aircraftResponse: aircraft
        });
      })
    ).subscribe(({ airportsResponse, waypointsResponse, aircraftResponse }) => {
      this.results.set(this.sortResults(airportsResponse, waypointsResponse, aircraftResponse));
    });

    this.breakpointObserver.observe(Breakpoints.MediumScreenMaxWidth)
      .pipe(
        distinctUntilChanged(),
        takeUntilDestroyed(this.destroyRef)
      ).subscribe(({ matches }) => {
        this.isMobileOrTabletSize = matches;
        if (this.shrinkWhenClosed) {
          this.showSearch = this.isShowingContent || !this.isMobileOrTabletSize;
        }
      });

    /**
     * If a click starts (via the mousedown event) inside and ends
     * outside, the click event will be fired from the closest common
     * ancestor of each:
     *
     * https://developer.mozilla.org/en-US/docs/Web/API/Element/click_event
     *
     * We still want the show/hide behavior to happen after mouseup,
     * so ignore the case when the click starts inside and is dragged
     * outside before releasing the mouse button.
     */
    fromEvent(document, 'mousedown')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((event: any) => {
        this.clickStartedInside = this.containsEvent(event);
      });

    fromEvent(document, 'click')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((event: any) => {
        let parent = event.target.parentNode;
        let infoClicked = false;

        // Don't close the search bar if we clicked airport/waypoint info
        if (this.mapStyling) {
          while (parent != null && !infoClicked) {
            if (parent.className === 'info-column') {
              infoClicked = true;
              this.radialMenuService?.closeIfOpen();
            }
            parent = parent.parentNode;
          }
        }

        if (!this.containsEvent(event) && !this.clickStartedInside && !infoClicked) {
          this.hideContent();

          if (this.shrinkWhenClosed && this.isMobileOrTabletSize) {
            this.showSearch = false;
          }
        }

        event.stopPropagation();
      });
  }

  get isShowingContent(): boolean {
    return this.showSearchContent;
  }

  /**
   * Checks if an event originated from inside this component,
   * including the fly-icon inside the fly-input, which is not part
   * of the node tree.
   *
   * @param event an event from `fromEvent`, such as a click or mousedown event
   * @returns true if the event came from inside this component, otherwise false
   */
  containsEvent(event: any): boolean {
    return (this.el.nativeElement.contains(event.target) === true
      || [
        event.target.className, // <fly-icon>
        event.target.parentNode?.className, // <svg>
        event.target.parentNode?.parentNode?.className // <use>
      ].includes('cancel-icon'));
  }

  hideContent(): void {
    if (this.radialMenuService != null && this.showSearchContent) {
      this.radialMenuService.searchHideContent();
    }
    this.showSearchContent = false;
  }

  showContent(): void {
    if (this.radialMenuService != null && !this.showSearchContent) {
      this.radialMenuService.searchShowContent();
    }
    this.showSearchContent = true;
  }

  onOpenSearch(): void {
    this.showSearch = true;
    this.cdr.detectChanges();
    this.input.inputEl.nativeElement.focus();
  }

  onSelectAirport(airport: AirportSearchResult): void {
    const airportId = airport.icao ?? airport.iata ?? airport.naa ?? '';
    this.addRecentAirport(
      {
        identifier: airportId,
        type: "AIRPORT",
        icon: airport.icon
      }
    );

    this.airportDataService.setAirportId(airportId);
    this.airportContent.set({ path: AirportContentPath.MetarTaf });

    // Update map
    const airportWaypoint = {
      type: MapWaypointType.CustomImage,
      name: airportId,
      latitude: airport.lat ?? 0,
      longitude: airport.lon ?? 0,
      customIcon: airport.icon,
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      textColor: 'white'
    } as MapWaypoint;
    this.mapService.state.fit({
      elements: [airportWaypoint],
      minZoom: 8,
      maxZoom: 10
    });

    this.formGroup.controls.searchControl.setValue('');
    this.results.set([]);
    this.showSearchContent = false;
  }

  onSelectWaypoint(waypoint: RouteComputedLeg): void {
    this.addRecentWaypoint({
      identifier: waypoint.identifier,
      countryCode: waypoint.countryCode,
      type: waypoint.locationType ?? '',
      navaidType: waypoint.navaidType
    });

    this.infoService.showWaypoint(waypoint, InfoWindowPosition.TopRight);

    // Update map
    const newWaypoint = {
      type: MapWaypointType.Icon,
      name: waypoint.identifier,
      latitude: waypoint.lat ?? 0,
      longitude: waypoint.lon ?? 0,
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      textColor: 'white',
      icon: waypoint.locationType != null ? WAYPOINT_ICON_MAP.get(waypoint.locationType) : MapIcon.Invalid
    } as MapWaypoint;
    this.mapService.data.waypoints.update((waypoints) => waypoints.concat(newWaypoint));
    this.mapService.state.fit({
      elements: [newWaypoint],
      minZoom: 8,
      maxZoom: 10
    });

    this.formGroup.controls.searchControl.setValue('');
    this.results.set([]);
    this.showSearchContent = false;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onSelectAircraft(aircraft: AcdcAircraftTrackResponse): void {
    //TODO: add in next PR when implementing traffic focus
    //TODO: center map to aircraft
    //TODO: open the aircraft tracking window
  }

  onSelectRecentItem(waypoint: StoredWaypoint): void {
    if (waypoint.type === "AIRPORT") {
      const id = waypoint.identifier;
      const foundAirport = this.recentlyViewedAirports.find((a) => a.icao === id || a.iata === id || a.naa === id);
      if (foundAirport != null)
        this.onSelectAirport(foundAirport);
    } else {
      const id = waypoint.identifier;
      const countryCode = waypoint.countryCode;
      const foundWaypoint = this.recentlyViewedWaypoints.find((w) => w.identifier === id && w.countryCode === countryCode);
      if (foundWaypoint != null)
        this.onSelectWaypoint(foundWaypoint);
    }
  }

  selectFirstResult(): void {
    const firstResult = this.results().at(0);
    if (firstResult?.airport != null) this.onSelectAirport(firstResult.airport);
    else if (firstResult?.waypoint != null) this.onSelectWaypoint(firstResult.waypoint);
  }

  getAirportIcon(waypoint: StoredWaypoint): string {
    return getWaypointIcon(waypoint) as string;
  }

  getWaypointIcon(waypoint: StoredWaypoint): Icon {
    return getWaypointIcon(waypoint) as Icon;
  }

  onCancelSearch(): void {
    this.formGroup.controls.searchControl.setValue('');
  }

  showTab(index: number): void {
    this.tabShown = index;
  }

  getAirportInfo(airportId: string): Observable<AirportModel | null> {
    return this.airportService.getAirportInfoById(airportId,
      [AirportDataSection.ADBRESPONSE]);
  }

  getWaypointInfo(waypointId?: string, countryCode?: string, exactSearch?: boolean): Observable<UnifiedTokenResponse> {
    if (waypointId != null) {
      const request: UnifiedTokenRequest = {
        name: waypointId,
        countryCode,
        exactSearch: exactSearch ?? false,
        // Ignore the airports found from here, since we have the airport service
        desiredLocationTypes: TOKEN_SEARCH_TYPES.filter((token) =>
          token !== UnifiedTokenRequestDesiredLocationTypes.AIRPORT)
      };
      if (this.isAuthenticated()) {
        return this.flightRouteService.unifiedTokenSearchPost(request);
      } else {
        return this.http.post<UnifiedTokenResponse>(
          `${environment.garmin.aviation.workerApiHost}/flights/token-search-unauthenticated`,
          request
        );
      }
    } else {
      console.error('Unable to search for waypoint with no identifier');
      return of({ resultsList: [] });
    }
  }

  addRecentAirport(airport: RecentWaypoint): void {
    this.recentWaypointsService.addRecentWaypoint({
      identifier: airport.identifier,
      type: "AIRPORT",
      icon: airport.icon
    });
  }

  addRecentWaypoint(waypoint: RecentWaypoint): void {
    this.recentWaypointsService.addRecentWaypoint({
      identifier: waypoint.identifier,
      countryCode: waypoint.countryCode,
      type: waypoint.type,
      navaidType: waypoint.navaidType
    });
  }

  getRecentAirportData(waypoints: StoredWaypoint[]): Observable<AirportSearchResult[]> {
    const airportIds = waypoints?.filter((w) => w.type === "AIRPORT").map((w) => w.identifier);
    const newAirportIds = airportIds?.filter((id) =>
      this.recentlyViewedAirports.find((a) => a.icao === id || a.iata === id || a.naa === id) == null);

    if (newAirportIds.length === 0) return of(this.recentlyViewedAirports);
    const currentIds = waypoints.map((w) => w.identifier);

    return this.airportService.getAirportsByIds(newAirportIds).pipe(
      switchMap((newResults: AirportSearchResponse | null) => {
        const cachedResults = this.recentlyViewedAirports.filter((a) => currentIds.includes(a.icao ?? a.iata ?? a.naa ?? ''));
        return of([...cachedResults, ...newResults?.airports ?? newAirportIds.map((id) => ({ icao: id } as AirportSearchResult))]);
      }),
      takeUntilDestroyed(this.destroyRef)
    );
  }

  getRecentWaypointData(waypoints: StoredWaypoint[]): Observable<RouteComputedLeg[]> {
    const newWaypoints = waypoints.filter((newWpt) =>
      newWpt.type !== 'AIRPORT'
      && this.recentlyViewedWaypoints.find(
        (wpt) => wpt.identifier === newWpt.identifier && wpt.countryCode === newWpt.countryCode
      ) == null);

    const data = newWaypoints.map((waypoint) => {
      return this.getWaypointInfo(waypoint.identifier, waypoint.countryCode, true).pipe(
        map((res) => res.resultsList?.at(0)),
        filter((res) => res != null)
      );
    });
    return forkJoin(data as Array<Observable<RouteComputedLeg>>);
  }

  trackWaypoints(waypoint: RouteComputedLeg): string {
    return `${waypoint.identifier}/${waypoint.countryCode}`;
  }

  sortResults(
    airportsResponse: AirportSearchResponse | null,
    waypointsResponse: UnifiedTokenResponse | null,
    aircraftResponse: AcdcAircraftTrackResponse | null
  ): SearchResult[] {
    const airports = airportsResponse?.airports ?? [];
    const waypoints = waypointsResponse?.resultsList ?? [];
    const aircrafts = aircraftResponse != null ? [aircraftResponse]: [];
    const results = [
      ...airports.map((airport) => ({ airport } as SearchResult)),
      ...waypoints.map((waypoint) => ({ waypoint } as SearchResult)),
      ...aircrafts.map((aircraft) => ({ aircraft } as SearchResult))
    ];

    const moveToFront = (array: any[], index: number): number => array.unshift(array.splice(index, 1)[0]);
    const searchText = this.formGroup.controls.searchControl.value;

    let i = 0;
    for (const result of results) {
      const airport = result.airport;
      const waypoint = result.waypoint;
      const aircraft = result.aircraft;
      if (airport != null
        && [airport.icao, airport.iata, airport.naa].includes(searchText.toUpperCase())) {
        moveToFront(results, i);
      } else if (waypoint != null && waypoint.identifier === searchText.toUpperCase()) {
        moveToFront(results, i);
      } else if (aircraft != null && aircraft.id.toUpperCase() === searchText.toUpperCase()){
        moveToFront(results, i);
      }
      i += 1;
    }
    return results;
  }
}
