/**
 * FlightOrchestratorService
 * flightOrchestratorService
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * Contact: #AVN-ProdEng-ProdExp-Cloud-Connectivity@garmin.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { AdminCustomerAccountResponse } from '../model/admin-customer-account-response.model';
// @ts-ignore
import { AdminEnabledResponse } from '../model/admin-enabled-response.model';
// @ts-ignore
import { AdminPdcFlightSearchParams } from '../model/admin-pdc-flight-search-params.model';
// @ts-ignore
import { AdminPdcFlightSearchResponse } from '../model/admin-pdc-flight-search-response.model';
// @ts-ignore
import { AdminUserDetailsRequest } from '../model/admin-user-details-request.model';
// @ts-ignore
import { BatchFlightFilingRequest } from '../model/batch-flight-filing-request.model';
// @ts-ignore
import { BatchFlightFilingResponse } from '../model/batch-flight-filing-response.model';
// @ts-ignore
import { DeniedFiling } from '../model/denied-filing.model';
// @ts-ignore
import { DeniedFilingChange } from '../model/denied-filing-change.model';
// @ts-ignore
import { DeniedFilingChangeResponse } from '../model/denied-filing-change-response.model';
// @ts-ignore
import { Flight } from '../model/flight.model';
// @ts-ignore
import { FlightActionsInfo } from '../model/flight-actions-info.model';
// @ts-ignore
import { FlightSearchParams } from '../model/flight-search-params.model';
// @ts-ignore
import { FlightStatusResponse } from '../model/flight-status-response.model';
// @ts-ignore
import { PilotPhoneVerifiedEntity } from '../model/pilot-phone-verified-entity.model';
// @ts-ignore
import { Route } from '../model/route.model';
// @ts-ignore
import { Subscription } from '../model/subscription.model';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { FlightOrchestratorServiceConfiguration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class AdminControllerService {

    protected basePath = 'https://avcloud-api-test.garmin.com/flightOrchestratorService';
    public defaultHeaders = new HttpHeaders();
    public configuration = new FlightOrchestratorServiceConfiguration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: FlightOrchestratorServiceConfiguration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Hard or soft delete a flight and any associated routes/routePoints
     * @param flightId 
     * @param hardDelete 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public _2(flightId: string, hardDelete: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any>;
    public _2(flightId: string, hardDelete: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpResponse<any>>;
    public _2(flightId: string, hardDelete: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpEvent<any>>;
    public _2(flightId: string, hardDelete: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any> {
        if (flightId === null || flightId === undefined) {
            throw new Error('Required parameter flightId was null or undefined when calling _2.');
        }
        if (hardDelete === null || hardDelete === undefined) {
            throw new Error('Required parameter hardDelete was null or undefined when calling _2.');
        }


        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (hardDelete !== undefined && hardDelete !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>hardDelete, 'hard_delete');
        }

        let localVarHeaders = this.defaultHeaders;
        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        const providedHeaders = options?.headers;

        if (providedHeaders != null) {
          if (providedHeaders instanceof HttpHeaders) {
            providedHeaders.keys().forEach((key) => localVarHeaders = localVarHeaders.append(key, providedHeaders.get(key)!))
          } else {
            Object.entries(providedHeaders).forEach(([key, value]) => localVarHeaders = localVarHeaders.append(key, value));
          }
        }
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/admin/${this.configuration.encodeParam({name: "flightId", value: flightId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add a DeniedFiling record
     * @param customerGuid 
     * @param deniedFiling 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addDeniedFiling(customerGuid: string, deniedFiling: DeniedFiling, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<DeniedFiling>;
    public addDeniedFiling(customerGuid: string, deniedFiling: DeniedFiling, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpResponse<DeniedFiling>>;
    public addDeniedFiling(customerGuid: string, deniedFiling: DeniedFiling, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpEvent<DeniedFiling>>;
    public addDeniedFiling(customerGuid: string, deniedFiling: DeniedFiling, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any> {
        if (customerGuid === null || customerGuid === undefined) {
            throw new Error('Required parameter customerGuid was null or undefined when calling addDeniedFiling.');
        }
        if (deniedFiling === null || deniedFiling === undefined) {
            throw new Error('Required parameter deniedFiling was null or undefined when calling addDeniedFiling.');
        }


        let localVarHeaders = this.defaultHeaders;
        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        const providedHeaders = options?.headers;

        if (providedHeaders != null) {
          if (providedHeaders instanceof HttpHeaders) {
            providedHeaders.keys().forEach((key) => localVarHeaders = localVarHeaders.append(key, providedHeaders.get(key)!))
          } else {
            Object.entries(providedHeaders).forEach(([key, value]) => localVarHeaders = localVarHeaders.append(key, value));
          }
        }
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/admin/${this.configuration.encodeParam({name: "customerGuid", value: customerGuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/denied-filing`;
        return this.httpClient.request<DeniedFiling>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: deniedFiling,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * update Flight record
     * @param flightId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addFlightStatus(flightId: string, body: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<string>;
    public addFlightStatus(flightId: string, body: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpResponse<string>>;
    public addFlightStatus(flightId: string, body: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpEvent<string>>;
    public addFlightStatus(flightId: string, body: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any> {
        if (flightId === null || flightId === undefined) {
            throw new Error('Required parameter flightId was null or undefined when calling addFlightStatus.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addFlightStatus.');
        }


        let localVarHeaders = this.defaultHeaders;
        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        const providedHeaders = options?.headers;

        if (providedHeaders != null) {
          if (providedHeaders instanceof HttpHeaders) {
            providedHeaders.keys().forEach((key) => localVarHeaders = localVarHeaders.append(key, providedHeaders.get(key)!))
          } else {
            Object.entries(providedHeaders).forEach(([key, value]) => localVarHeaders = localVarHeaders.append(key, value));
          }
        }
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/admin/flight-status/flight/${this.configuration.encodeParam({name: "flightId", value: flightId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<string>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: body,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * add UserDetails record
     * @param customerGuid 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addUserDetails(customerGuid: string, body: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<string>;
    public addUserDetails(customerGuid: string, body: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpResponse<string>>;
    public addUserDetails(customerGuid: string, body: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpEvent<string>>;
    public addUserDetails(customerGuid: string, body: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any> {
        if (customerGuid === null || customerGuid === undefined) {
            throw new Error('Required parameter customerGuid was null or undefined when calling addUserDetails.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addUserDetails.');
        }


        let localVarHeaders = this.defaultHeaders;
        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        const providedHeaders = options?.headers;

        if (providedHeaders != null) {
          if (providedHeaders instanceof HttpHeaders) {
            providedHeaders.keys().forEach((key) => localVarHeaders = localVarHeaders.append(key, providedHeaders.get(key)!))
          } else {
            Object.entries(providedHeaders).forEach(([key, value]) => localVarHeaders = localVarHeaders.append(key, value));
          }
        }
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/admin/user-details/customerId/${this.configuration.encodeParam({name: "customerGuid", value: customerGuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<string>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: body,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Cancel each flight-id provided
     * @param batchFlightFilingRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public cancelMultipleFlights(batchFlightFilingRequest: BatchFlightFilingRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<BatchFlightFilingResponse>;
    public cancelMultipleFlights(batchFlightFilingRequest: BatchFlightFilingRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpResponse<BatchFlightFilingResponse>>;
    public cancelMultipleFlights(batchFlightFilingRequest: BatchFlightFilingRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpEvent<BatchFlightFilingResponse>>;
    public cancelMultipleFlights(batchFlightFilingRequest: BatchFlightFilingRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any> {
        if (batchFlightFilingRequest === null || batchFlightFilingRequest === undefined) {
            throw new Error('Required parameter batchFlightFilingRequest was null or undefined when calling cancelMultipleFlights.');
        }


        let localVarHeaders = this.defaultHeaders;
        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        const providedHeaders = options?.headers;

        if (providedHeaders != null) {
          if (providedHeaders instanceof HttpHeaders) {
            providedHeaders.keys().forEach((key) => localVarHeaders = localVarHeaders.append(key, providedHeaders.get(key)!))
          } else {
            Object.entries(providedHeaders).forEach(([key, value]) => localVarHeaders = localVarHeaders.append(key, value));
          }
        }
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/admin/flights/file/cancel`;
        return this.httpClient.request<BatchFlightFilingResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: batchFlightFilingRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * File each flight-id provided
     * @param batchFlightFilingRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fileMultipleFlights(batchFlightFilingRequest: BatchFlightFilingRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<BatchFlightFilingResponse>;
    public fileMultipleFlights(batchFlightFilingRequest: BatchFlightFilingRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpResponse<BatchFlightFilingResponse>>;
    public fileMultipleFlights(batchFlightFilingRequest: BatchFlightFilingRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpEvent<BatchFlightFilingResponse>>;
    public fileMultipleFlights(batchFlightFilingRequest: BatchFlightFilingRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any> {
        if (batchFlightFilingRequest === null || batchFlightFilingRequest === undefined) {
            throw new Error('Required parameter batchFlightFilingRequest was null or undefined when calling fileMultipleFlights.');
        }


        let localVarHeaders = this.defaultHeaders;
        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        const providedHeaders = options?.headers;

        if (providedHeaders != null) {
          if (providedHeaders instanceof HttpHeaders) {
            providedHeaders.keys().forEach((key) => localVarHeaders = localVarHeaders.append(key, providedHeaders.get(key)!))
          } else {
            Object.entries(providedHeaders).forEach(([key, value]) => localVarHeaders = localVarHeaders.append(key, value));
          }
        }
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/admin/flights/file`;
        return this.httpClient.request<BatchFlightFilingResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: batchFlightFilingRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * filters flights with corresponding matching fields
     * @param flight 
     * @param offset 
     * @param limit 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public filterFlightsByFields(flight: Flight, offset: number, limit: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<Array<Flight>>;
    public filterFlightsByFields(flight: Flight, offset: number, limit: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpResponse<Array<Flight>>>;
    public filterFlightsByFields(flight: Flight, offset: number, limit: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpEvent<Array<Flight>>>;
    public filterFlightsByFields(flight: Flight, offset: number, limit: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any> {
        if (offset === null || offset === undefined) {
            throw new Error('Required parameter offset was null or undefined when calling filterFlightsByFields.');
        }
        if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling filterFlightsByFields.');
        }
        if (flight === null || flight === undefined) {
            throw new Error('Required parameter flight was null or undefined when calling filterFlightsByFields.');
        }


        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (offset !== undefined && offset !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>offset, 'offset');
        }
        if (limit !== undefined && limit !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>limit, 'limit');
        }

        let localVarHeaders = this.defaultHeaders;
        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        const providedHeaders = options?.headers;

        if (providedHeaders != null) {
          if (providedHeaders instanceof HttpHeaders) {
            providedHeaders.keys().forEach((key) => localVarHeaders = localVarHeaders.append(key, providedHeaders.get(key)!))
          } else {
            Object.entries(providedHeaders).forEach(([key, value]) => localVarHeaders = localVarHeaders.append(key, value));
          }
        }
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/admin/flights/matching`;
        return this.httpClient.request<Array<Flight>>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: flight,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get list of all known unique customer ids
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllKnownUsers(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<Array<string>>;
    public getAllKnownUsers(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpResponse<Array<string>>>;
    public getAllKnownUsers(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpEvent<Array<string>>>;
    public getAllKnownUsers(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any> {


        let localVarHeaders = this.defaultHeaders;
        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        const providedHeaders = options?.headers;

        if (providedHeaders != null) {
          if (providedHeaders instanceof HttpHeaders) {
            providedHeaders.keys().forEach((key) => localVarHeaders = localVarHeaders.append(key, providedHeaders.get(key)!))
          } else {
            Object.entries(providedHeaders).forEach(([key, value]) => localVarHeaders = localVarHeaders.append(key, value));
          }
        }
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/admin/user-details/customer-ids`;
        return this.httpClient.request<Array<string>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get count of unique customers
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCustomerCount(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<number>;
    public getCustomerCount(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpResponse<number>>;
    public getCustomerCount(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpEvent<number>>;
    public getCustomerCount(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any> {


        let localVarHeaders = this.defaultHeaders;
        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        const providedHeaders = options?.headers;

        if (providedHeaders != null) {
          if (providedHeaders instanceof HttpHeaders) {
            providedHeaders.keys().forEach((key) => localVarHeaders = localVarHeaders.append(key, providedHeaders.get(key)!))
          } else {
            Object.entries(providedHeaders).forEach(([key, value]) => localVarHeaders = localVarHeaders.append(key, value));
          }
        }
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/admin/customer-id/count`;
        return this.httpClient.request<number>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets all Garmin Pilot related active subscriptions for a user.
     * @param customerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCustomerGpSubscriptions(customerId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<Array<Subscription>>;
    public getCustomerGpSubscriptions(customerId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpResponse<Array<Subscription>>>;
    public getCustomerGpSubscriptions(customerId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpEvent<Array<Subscription>>>;
    public getCustomerGpSubscriptions(customerId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any> {
        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling getCustomerGpSubscriptions.');
        }


        let localVarHeaders = this.defaultHeaders;
        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        const providedHeaders = options?.headers;

        if (providedHeaders != null) {
          if (providedHeaders instanceof HttpHeaders) {
            providedHeaders.keys().forEach((key) => localVarHeaders = localVarHeaders.append(key, providedHeaders.get(key)!))
          } else {
            Object.entries(providedHeaders).forEach(([key, value]) => localVarHeaders = localVarHeaders.append(key, value));
          }
        }
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/admin/${this.configuration.encodeParam({name: "customerId", value: customerId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/subscriptions/gp`;
        return this.httpClient.request<Array<Subscription>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets the filing status + gpSync ability of a flight, by its flight ID
     * @param flightId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFlightActionsInfo(flightId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<FlightActionsInfo>;
    public getFlightActionsInfo(flightId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpResponse<FlightActionsInfo>>;
    public getFlightActionsInfo(flightId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpEvent<FlightActionsInfo>>;
    public getFlightActionsInfo(flightId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any> {
        if (flightId === null || flightId === undefined) {
            throw new Error('Required parameter flightId was null or undefined when calling getFlightActionsInfo.');
        }


        let localVarHeaders = this.defaultHeaders;
        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        const providedHeaders = options?.headers;

        if (providedHeaders != null) {
          if (providedHeaders instanceof HttpHeaders) {
            providedHeaders.keys().forEach((key) => localVarHeaders = localVarHeaders.append(key, providedHeaders.get(key)!))
          } else {
            Object.entries(providedHeaders).forEach(([key, value]) => localVarHeaders = localVarHeaders.append(key, value));
          }
        }
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/admin/${this.configuration.encodeParam({name: "flightId", value: flightId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/status`;
        return this.httpClient.request<FlightActionsInfo>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets the flight details (Flight, route, finalPerfData) for the flightId
     * @param flightId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFlightStatus(flightId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<string>;
    public getFlightStatus(flightId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpResponse<string>>;
    public getFlightStatus(flightId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpEvent<string>>;
    public getFlightStatus(flightId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any> {
        if (flightId === null || flightId === undefined) {
            throw new Error('Required parameter flightId was null or undefined when calling getFlightStatus.');
        }


        let localVarHeaders = this.defaultHeaders;
        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        const providedHeaders = options?.headers;

        if (providedHeaders != null) {
          if (providedHeaders instanceof HttpHeaders) {
            providedHeaders.keys().forEach((key) => localVarHeaders = localVarHeaders.append(key, providedHeaders.get(key)!))
          } else {
            Object.entries(providedHeaders).forEach(([key, value]) => localVarHeaders = localVarHeaders.append(key, value));
          }
        }
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/admin/flight-status/flight/${this.configuration.encodeParam({name: "flightId", value: flightId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<string>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get flights which have PDC routes associated with them.
     * @param searchParams 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFlightsWithPDCs(searchParams: AdminPdcFlightSearchParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<AdminPdcFlightSearchResponse>;
    public getFlightsWithPDCs(searchParams: AdminPdcFlightSearchParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpResponse<AdminPdcFlightSearchResponse>>;
    public getFlightsWithPDCs(searchParams: AdminPdcFlightSearchParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpEvent<AdminPdcFlightSearchResponse>>;
    public getFlightsWithPDCs(searchParams: AdminPdcFlightSearchParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any> {
        if (searchParams === null || searchParams === undefined) {
            throw new Error('Required parameter searchParams was null or undefined when calling getFlightsWithPDCs.');
        }


        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (searchParams !== undefined && searchParams !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>searchParams, 'searchParams');
        }

        let localVarHeaders = this.defaultHeaders;
        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        const providedHeaders = options?.headers;

        if (providedHeaders != null) {
          if (providedHeaders instanceof HttpHeaders) {
            providedHeaders.keys().forEach((key) => localVarHeaders = localVarHeaders.append(key, providedHeaders.get(key)!))
          } else {
            Object.entries(providedHeaders).forEach(([key, value]) => localVarHeaders = localVarHeaders.append(key, value));
          }
        }
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/admin/flights/pdc`;
        return this.httpClient.request<AdminPdcFlightSearchResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the latest test message
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getLatestTestMessage(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<string>;
    public getLatestTestMessage(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpResponse<string>>;
    public getLatestTestMessage(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpEvent<string>>;
    public getLatestTestMessage(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any> {


        let localVarHeaders = this.defaultHeaders;
        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        const providedHeaders = options?.headers;

        if (providedHeaders != null) {
          if (providedHeaders instanceof HttpHeaders) {
            providedHeaders.keys().forEach((key) => localVarHeaders = localVarHeaders.append(key, providedHeaders.get(key)!))
          } else {
            Object.entries(providedHeaders).forEach(([key, value]) => localVarHeaders = localVarHeaders.append(key, value));
          }
        }
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/admin/event/test/message`;
        return this.httpClient.request<string>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets phone verified info of all pilots
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPilotPhoneVerifiedInfo(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<Array<PilotPhoneVerifiedEntity>>;
    public getPilotPhoneVerifiedInfo(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpResponse<Array<PilotPhoneVerifiedEntity>>>;
    public getPilotPhoneVerifiedInfo(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpEvent<Array<PilotPhoneVerifiedEntity>>>;
    public getPilotPhoneVerifiedInfo(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any> {


        let localVarHeaders = this.defaultHeaders;
        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        const providedHeaders = options?.headers;

        if (providedHeaders != null) {
          if (providedHeaders instanceof HttpHeaders) {
            providedHeaders.keys().forEach((key) => localVarHeaders = localVarHeaders.append(key, providedHeaders.get(key)!))
          } else {
            Object.entries(providedHeaders).forEach(([key, value]) => localVarHeaders = localVarHeaders.append(key, value));
          }
        }
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/admin/pilot/phone-verified`;
        return this.httpClient.request<Array<PilotPhoneVerifiedEntity>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * gets user account information with corresponding matching fields
     * @param adminUserDetailsRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserAccountDetails(adminUserDetailsRequest: AdminUserDetailsRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<AdminCustomerAccountResponse>;
    public getUserAccountDetails(adminUserDetailsRequest: AdminUserDetailsRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpResponse<AdminCustomerAccountResponse>>;
    public getUserAccountDetails(adminUserDetailsRequest: AdminUserDetailsRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpEvent<AdminCustomerAccountResponse>>;
    public getUserAccountDetails(adminUserDetailsRequest: AdminUserDetailsRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any> {
        if (adminUserDetailsRequest === null || adminUserDetailsRequest === undefined) {
            throw new Error('Required parameter adminUserDetailsRequest was null or undefined when calling getUserAccountDetails.');
        }


        let localVarHeaders = this.defaultHeaders;
        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        const providedHeaders = options?.headers;

        if (providedHeaders != null) {
          if (providedHeaders instanceof HttpHeaders) {
            providedHeaders.keys().forEach((key) => localVarHeaders = localVarHeaders.append(key, providedHeaders.get(key)!))
          } else {
            Object.entries(providedHeaders).forEach(([key, value]) => localVarHeaders = localVarHeaders.append(key, value));
          }
        }
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/admin/user-details`;
        return this.httpClient.request<AdminCustomerAccountResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: adminUserDetailsRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets bookmarked routes of specified customer
     * @param customerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserBookmarkedRoutes(customerId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<Array<Route>>;
    public getUserBookmarkedRoutes(customerId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpResponse<Array<Route>>>;
    public getUserBookmarkedRoutes(customerId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpEvent<Array<Route>>>;
    public getUserBookmarkedRoutes(customerId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any> {
        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling getUserBookmarkedRoutes.');
        }


        let localVarHeaders = this.defaultHeaders;
        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        const providedHeaders = options?.headers;

        if (providedHeaders != null) {
          if (providedHeaders instanceof HttpHeaders) {
            providedHeaders.keys().forEach((key) => localVarHeaders = localVarHeaders.append(key, providedHeaders.get(key)!))
          } else {
            Object.entries(providedHeaders).forEach(([key, value]) => localVarHeaders = localVarHeaders.append(key, value));
          }
        }
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/admin/${this.configuration.encodeParam({name: "customerId", value: customerId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/routes/bookmarked`;
        return this.httpClient.request<Array<Route>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets the user details for the user with customer_guid
     * @param customerGuid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserDetails(customerGuid: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<string>;
    public getUserDetails(customerGuid: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpResponse<string>>;
    public getUserDetails(customerGuid: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpEvent<string>>;
    public getUserDetails(customerGuid: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any> {
        if (customerGuid === null || customerGuid === undefined) {
            throw new Error('Required parameter customerGuid was null or undefined when calling getUserDetails.');
        }


        let localVarHeaders = this.defaultHeaders;
        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        const providedHeaders = options?.headers;

        if (providedHeaders != null) {
          if (providedHeaders instanceof HttpHeaders) {
            providedHeaders.keys().forEach((key) => localVarHeaders = localVarHeaders.append(key, providedHeaders.get(key)!))
          } else {
            Object.entries(providedHeaders).forEach(([key, value]) => localVarHeaders = localVarHeaders.append(key, value));
          }
        }
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/admin/user-details/customerId/${this.configuration.encodeParam({name: "customerGuid", value: customerGuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<string>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets the denied filing records for the user with customer-guid
     * @param customerGuid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserFilingStatus(customerGuid: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<Array<DeniedFiling>>;
    public getUserFilingStatus(customerGuid: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpResponse<Array<DeniedFiling>>>;
    public getUserFilingStatus(customerGuid: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpEvent<Array<DeniedFiling>>>;
    public getUserFilingStatus(customerGuid: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any> {
        if (customerGuid === null || customerGuid === undefined) {
            throw new Error('Required parameter customerGuid was null or undefined when calling getUserFilingStatus.');
        }


        let localVarHeaders = this.defaultHeaders;
        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        const providedHeaders = options?.headers;

        if (providedHeaders != null) {
          if (providedHeaders instanceof HttpHeaders) {
            providedHeaders.keys().forEach((key) => localVarHeaders = localVarHeaders.append(key, providedHeaders.get(key)!))
          } else {
            Object.entries(providedHeaders).forEach(([key, value]) => localVarHeaders = localVarHeaders.append(key, value));
          }
        }
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/admin/${this.configuration.encodeParam({name: "customerGuid", value: customerGuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/denied-filing-history`;
        return this.httpClient.request<Array<DeniedFiling>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * returns if admin capabilities are active. If they are not active, the Phoenix AdminPortal actions will not be displayed on Phoenix AdminPortal
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public isEnabled(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<AdminEnabledResponse>;
    public isEnabled(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpResponse<AdminEnabledResponse>>;
    public isEnabled(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpEvent<AdminEnabledResponse>>;
    public isEnabled(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any> {


        let localVarHeaders = this.defaultHeaders;
        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        const providedHeaders = options?.headers;

        if (providedHeaders != null) {
          if (providedHeaders instanceof HttpHeaders) {
            providedHeaders.keys().forEach((key) => localVarHeaders = localVarHeaders.append(key, providedHeaders.get(key)!))
          } else {
            Object.entries(providedHeaders).forEach(([key, value]) => localVarHeaders = localVarHeaders.append(key, value));
          }
        }
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/admin/enabled`;
        return this.httpClient.request<AdminEnabledResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Matches for flights by convenient fields such as aircraft, departure, and arrival
     * @param flightSearchParams 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public matchFlights(flightSearchParams: FlightSearchParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<Array<FlightStatusResponse>>;
    public matchFlights(flightSearchParams: FlightSearchParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpResponse<Array<FlightStatusResponse>>>;
    public matchFlights(flightSearchParams: FlightSearchParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpEvent<Array<FlightStatusResponse>>>;
    public matchFlights(flightSearchParams: FlightSearchParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any> {
        if (flightSearchParams === null || flightSearchParams === undefined) {
            throw new Error('Required parameter flightSearchParams was null or undefined when calling matchFlights.');
        }


        let localVarHeaders = this.defaultHeaders;
        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        const providedHeaders = options?.headers;

        if (providedHeaders != null) {
          if (providedHeaders instanceof HttpHeaders) {
            providedHeaders.keys().forEach((key) => localVarHeaders = localVarHeaders.append(key, providedHeaders.get(key)!))
          } else {
            Object.entries(providedHeaders).forEach(([key, value]) => localVarHeaders = localVarHeaders.append(key, value));
          }
        }
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/admin/flights/search`;
        return this.httpClient.request<Array<FlightStatusResponse>>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: flightSearchParams,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a denied filing record for the user with customer-guid
     * @param customerGuid 
     * @param deniedFilingChange 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateDeniedFiling(customerGuid: string, deniedFilingChange: DeniedFilingChange, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<DeniedFilingChangeResponse>;
    public updateDeniedFiling(customerGuid: string, deniedFilingChange: DeniedFilingChange, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpResponse<DeniedFilingChangeResponse>>;
    public updateDeniedFiling(customerGuid: string, deniedFilingChange: DeniedFilingChange, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<HttpEvent<DeniedFilingChangeResponse>>;
    public updateDeniedFiling(customerGuid: string, deniedFilingChange: DeniedFilingChange, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*' | 'application/json', context?: HttpContext, headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any> {
        if (customerGuid === null || customerGuid === undefined) {
            throw new Error('Required parameter customerGuid was null or undefined when calling updateDeniedFiling.');
        }
        if (deniedFilingChange === null || deniedFilingChange === undefined) {
            throw new Error('Required parameter deniedFilingChange was null or undefined when calling updateDeniedFiling.');
        }


        let localVarHeaders = this.defaultHeaders;
        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        const providedHeaders = options?.headers;

        if (providedHeaders != null) {
          if (providedHeaders instanceof HttpHeaders) {
            providedHeaders.keys().forEach((key) => localVarHeaders = localVarHeaders.append(key, providedHeaders.get(key)!))
          } else {
            Object.entries(providedHeaders).forEach(([key, value]) => localVarHeaders = localVarHeaders.append(key, value));
          }
        }
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/admin/${this.configuration.encodeParam({name: "customerGuid", value: customerGuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/denied-filing`;
        return this.httpClient.request<DeniedFilingChangeResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: deniedFilingChange,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
